<template>
    <div class="box" v-if="props.topItems">
        <div class="is-flex is-justify-content-space-between is-align-items-flex-start">
            <div class="box-title">Top Items</div>
            <div>
                <button class="button is-primary is-small" :class="{ 'is-loading': isExporting }"
                        :disabled="isExporting" @click="emit('export')">
                    Export
                </button>
            </div>
        </div>

        <VisXYContainer class="block" :data="topItemsGraph" style="min-height: 40vh;">
            <VisStackedBar :x="(d) => d.x" :y="(d) => d.y" />
            <VisAxis type="x" :tickTextWidth="150" tickTextFitMode="wrap"
                     :tickFormat="(t: number) => topItemsCategories[t]" :tickTextForceWordBreak="true" />
            <VisAxis label="Quantity Sold" type="y" />
        </VisXYContainer>

        <div class="table-container">
            <table class="table is-width-100 block">
                <thead class="has-background-primary-25">
                <tr>
                    <th>Item Name</th>
                    <th>Quantity Sold</th>
                    <th>Total Sales</th>
                    <th>Total Cost of Goods</th>
                    <th>Total Profit</th>
                </tr>
                </thead>
                <tbody class="has-background-primary-05">
                <tr v-for="data in topItems.data" :key="data.itemId">
                    <td>{{ data.item.title }}</td>
                    <td>{{ data.quantity }}</td>
                    <td>{{ formatNumber(data.totalSales) }}</td>
                    <td>{{ formatNumber(data.totalCostOfGoods) }}</td>
                    <td>{{ formatNumber(data.totalProfit) }}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="field is-grouped block">
            <p class="control" v-for="link in topItems.links">
                <button
                    v-if="link.url"
                    class="button is-primary"
                    :disabled="link.active"
                    v-html="link.label"
                    @click="emit('getPage', link.url)"
                />
                <button
                    v-else
                    class="button is-primary"
                    :disabled="!link.active"
                    v-html="link.label"
                ></button>
            </p>
        </div>
    </div>
    <div v-else class="box">
        <div class="skeleton-block"></div>
    </div>
</template>

<script setup lang="ts">
import {VisAxis, VisStackedBar, VisXYContainer} from "@unovis/vue";
import {Report, ReportInjection} from "@/Types/Report";
import {computed, inject} from "vue";

const props = defineProps<{
    topItems: Report['topItems'];
}>();

const emit = defineEmits<{
    (e: 'getPage', url: string): void;
    (e: 'export'): void;
}>();

const topItemsGraph = computed(() => {
    if (!props.topItems) {
        return;
    }

    const obj = props.topItems.data.map((item, i) => ({
        x: i,
        y: item.quantity
    }));

    return obj;
});

const topItemsCategories = computed(() => {
    if (!props.topItems) {
        return;
    }

    return props.topItems.data.map(item => item.item.title);
});

const {
    isExporting,
    formatNumber,
} = inject<ReportInjection>('shopReports');

</script>
